<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-18 09:08:55
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-11-04 20:25:47
-->
<template>
    <div class="screenSelect">
        <div class="bw-item">
            <div class="bt-title">筛选日期</div>
            <div class="filtrate-wrap">
                <div class="fw-date">
                    <div v-for="(item,index) in radioList" :key="index" @click="clickTagDate(index,item)"
                        class="date-item" :class="currentTag == index?'tagActive':''">
                            {{item.name}}
                    </div>
                    <el-date-picker v-if="isDate"
                        class="date-wrap"
                        v-model="dateValue"
                        type="daterange"
                        :picker-options="setDateRange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        @change="changeDate"
                        >
                    </el-date-picker>
                </div>
            </div>
        </div>
        <div class="bw-item" v-if="type!=1">
            <div class="bt-title">跟进阶段</div>
            <div class="filtrate-wrap">
                <div class="fw-date">
                    <div v-for="(item,index) in followList" :key="index" @click="clickTagfollow(index,item)"
                        class="date-item" :class="currentFollow == index?'tagActive':''">
                            {{item.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="bw-item">
            <div class="bt-title">跟进频率</div>
            <div class="filtrate-wrap">
                <div class="fw-date">
                    <div v-for="(item,index) in frequencyList" :key="index" @click="clickTagFrequency(index,item)"
                        class="date-item" :class="currentQuality == index?'tagActive':''">
                            {{item.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="bw-item">
            <div class="bt-title">客户类型</div>
            <div class="filtrate-wrap">
                <div class="fw-date">
                    <div v-for="(item,index) in typeList" :key="index" @click="clickTagtype(index,item)"
                        class="date-item" :class="currentType == index?'tagActive':''">
                            {{item.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="bw-item" v-if="type==1">
            <div class="bt-title">渠道类型</div>
            <div class="filtrate-wrap">
                <div class="fw-date">
                    <div v-for="(item,index) in channelList" :key="index" @click="clickChanneltype(index,item)"
                        class="date-item" :class="currentChannel == index?'tagActive':''">
                            {{item.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="bw-item">
            <div class="bt-title">成员</div>
            <div class="filtrate-wrap">
                <div class="fw-date">
                    <div v-for="(item,index) in memberList" :key="index" @click="clickTagmember(index,item)"
                        class="date-item" :class="currentMember == index?'tagActive':''">
                            {{item.name}}
                    </div>
                </div>
            </div>
        </div>
        <div class="foot-flex">
            <el-button class="reset" @click="reset" type="">重置</el-button>
            <el-button class="confirm" @click="confirm" type="primary">确定</el-button>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import {timestampToTime} from "@/utils/timeCycle";
export default {
    props:{
        type:{ 
            type:Number,
            default: 0
        }
    },
    data () {
        return {
            radioList: [{
                name: '全部',
                label: ''
            },{
              name: '今天',
              label: 'today'
            },{
              name: '昨天',
              label: 'yesterday'
            },{
                name: '本周',
                label: 'week'
            },{
                name: '上周',
                label: 'last week'
            },{
                name: '本月',
                label: 'month'
            },{
                name: '上月',
                label: 'last month'
            },{
                name: '本季',
                label: 'quarter'
            },{
                name: '上季',
                label: 'last quarter'
            },{
                name: '自定义',
                label: 'date'
            }],
            followList: [{
                name: '全部',
                label: ''
            },{
              name: '无状态',
              label: '1'
            },{
              name: '意向客户',
              label: '2'
            },{
              name: '方案报价',
              label: '3'
            },{
              name: '成交客户',
              label: '4'
            },{
              name: '回访客户',
              label: '5'
            }],
            typeList: [{
                name: '全部',
                label: ''
            },{
              name: '有效客户',
              label: '-3'
            },{
              name: '无效客户',
              label: '3'
            }],
            channelList: [],
            frequencyList: [],
            memberList: [],
            currentTag: 0,
            currentFollow: 0,
            currentQuality: 0,
            currentType: 0,
            currentMember: 0,
            currentChannel: 0,
            isDate: false,
            dateValue: null,
            setDateRange: {  // 禁用今天之后的日期
                disabledDate: time => {
                    return time.getTime() > Date.now();
                }
            },
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            screen:{
                filter_type: '',
                dateType: '',
                startDate: '',
                endDate: '',
                label_type: '',
                user_id: '',
                customer_type_id: '',
                customer_frequency_id: '',
            }
        }
    },
    mounted () {
        this.getUserConditions();
        this.getCustomerFrequency();
        this.getCustomerTypeConditions();
    },
    methods: {
        // 确定
        confirm(){
            this.$emit("clickTag",this.screen)
        },
        //重置
        reset(){
            let screen = {
                filter_type: '',
                dateType: '',
                startDate: '',
                endDate: '',
                label_type: '',
                user_id: '',
                customer_type_id: '',
                customer_frequency_id: '',
            }
            this.screen = screen;
            this.currentTag = 0;
            this.currentFollow = 0;
            this.currentQuality = 0;
            this.currentType = 0;
            this.currentMember = 0;
            this.currentChannel = 0;
        },
        // 跟进频率
        getCustomerFrequency(){
            common.connect('/distributorpcv1/customer/customerFrequency',{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                    return{
                        label: v.sys_frequency_id,
                        name: v.name
                    }
                })
                this.frequencyList = resultSecond;
                let quan = {
                    name: '全部',
                    label: ''
                }
                this.frequencyList.unshift(quan)
            })
        },
        // 渠道类型
        getCustomerTypeConditions(){
            common.connect('/distributorpcv1/customer/customerTypeConditions',{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                    return{
                        label: v.id,
                        name: v.name
                    }
                })
                this.channelList = resultSecond;
                let quan = {
                    name: '全部',
                    label: ''
                }
                this.channelList.unshift(quan)
            })
        },
        // 获取成员列表
        getUserConditions(){
            common.connect('/distributorpcv1/user/userConditions',{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                    return{
                        label: v.user_id,
                        name: v.username
                    }
                })
                this.memberList = resultSecond;
                let quan = {
                    name: '全部',
                    label: ''
                }
                this.memberList.unshift(quan)
            })
        },
        //点击选择日期
        changeDate(date){
            if (this.dateValue && this.dateValue.length > 0) {
                const timestampBegin = +new Date(this.dateValue[0])
                const timestampEnd = +new Date(this.dateValue[1])
                if (timestampEnd > timestampBegin + 3600 * 1000 * 24 * 40) {
                    this.$alert('选择的时间范围不能超过40天', '提示', {
                            confirmButtonText: '确定',
                            type: 'warning'
                        })
                        .then(() => (this.dateValue = null))
                        .catch(() => (this.dateValue = null))
                }else{
                    let startDate = timestampToTime(date[0]);
                    let endDate = timestampToTime(date[1]);
                    this.screen.startDate = startDate.slice(0,11)+'00:00:01';
                    this.screen.endDate = endDate.slice(0,11)+'23:59:59';
                }
            }
        },
        // 渠道类型
        clickChanneltype(index,item){
            this.screen.customer_type_id = item.label;
            this.currentChannel = index
        },
         // 成员选择点击切换
        clickTagmember(index,item){
            this.screen.user_id = item.label;
            this.currentMember = index
        },
         // 客户类型选择点击切换
        clickTagtype(index,item){
            this.screen.filter_type = item.label;
            this.currentType = index
        },
        // 跟进频率选择点击切换
        clickTagFrequency(index,item){
            this.screen.customer_frequency_id = item.label;
            this.currentQuality = index
        },
        // 跟进阶段选择点击切换
        clickTagfollow(index,item){
            this.screen.label_type = item.label;
            this.currentFollow = index;
        },
        // 日期选择点击切换
        clickTagDate(index,item){
            this.screen.dateType = item.label;
            this.currentTag = index
            if(item.label == 'date'){
                this.isDate = true;
            }else{
                this.isDate = false;
            }
        },
    },
}
</script>

<style scoped lang='scss'>
.screenSelect{
    padding: 10px 0px 0px 20px;
    .bw-item{
        margin-top: 10px;
        .bt-title{
            font-size: 14px;
            color: #000;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .filtrate-wrap{
            background: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 6px;
            .fw-date{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .date-item{
                    margin-right: 15px;
                    background: #eee;
                    color: #333;
                    width: 80px;
                    text-align: center;
                    padding: 10px 0;
                    border-radius: 6px;
                    font-size: 14px;
                    cursor: pointer;
                    margin-bottom: 10px;
                }
                .tagActive{
                    background: #409EFF;
                    color: #fff;
                }
                .date-wrap{
                    height: 37px;
                }
            }
            .fw-shop{
                border: 1px solid #ddd;
                padding: 8px 18px;
                border-radius: 6px;
                font-size: 14px;
                cursor: pointer;
            }
        }
    }
    .foot-flex{
        width: calc(100% - 40px);
        position: absolute;
        bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .reset{
            flex: 2;
        }
        .confirm{
            flex: 3;
        }
        .el-button {
            padding: 15px 20px;
        }
    }
}
</style>
