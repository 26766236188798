<!--
 * @Descripttion: 客户线索
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-19 14:08:35
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-19 14:42:37
-->
<template>
    <div class="customerClues">
        <div class="item">
            <div class="item-tit">来源渠道</div>
            <div class="item-con">{{clues.source}}</div>
        </div>
        <div class="item">
            <div class="item-tit">性别</div>
            <div class="item-con">{{clues.sex}}</div>
        </div>
        <div class="item">
            <div class="item-tit">年龄</div>
            <div class="item-con">{{clues.age}}</div>
        </div>
        <div class="item">
            <div class="item-tit">使用产品时间</div>
            <div class="item-con">{{clues.purpose_time}}</div>
        </div>
        <div class="item">
            <div class="item-tit">装修风格喜好</div>
            <div class="item-con">{{clues.fav_style}}</div>
        </div>
        <div class="item">
            <div class="item-tit">消费预算</div>
            <div class="item-con">{{clues.price_rank}}</div>
        </div>
        <div class="item">
            <div class="item-tit">楼层</div>
            <div class="item-con">{{clues.floor}}</div>
        </div>
        <div class="item">
            <div class="item-tit">面积</div>
            <div class="item-con">{{clues.area}}</div>
        </div>
        <div class="item">
            <div class="item-tit">喜好本店产品</div>
            <div class="item-con">{{clues.fav_good}}</div>
        </div>
        <div class="item">
            <div class="item-tit">消费力判断</div>
            <div class="item-con">{{clues.purchasing_power}}</div>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
export default {
    data () {
        return {
            clues: {}
        }
    },
    mounted () {

    },
    methods: {
        setValue(id){
            common.connect('/distributorpcv1/customer/customerLeads',{id: id},(res)=>{
               this.clues = res.data;
            })
        }
    },
}
</script>

<style lang='scss'>
.customerClues{
    padding: 20px;
    .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 0px;
        border-top: 1px solid #eee;
        .item-tit{
            font-size: 16px;
            color: #000;
        }
        .item-con{
            font-size: 16px;
            color: #999;
        }
    }
}
</style>
