<!--
 * @Descripttion: 客户详情
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-19 11:40:21
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-10-31 14:17:49
-->
<template>
    <div class="customerDetails">
        <div class="content-wrap" v-loading="infoLoading">
            <!-- <div class="bar">跟进阶段：{{info.label_type_transform}}</div> -->
            <div class="info-wrap">
                <div class="head" v-if="info.user">
                    <img :src="info.user.head" class="pic">
                </div>
                <div class="info">
                    <div class="tit-wrap">
                        <div class="tit">{{info.name}}</div>
                        <div @click="gotoClues" class="clues">客户线索</div>
                    </div>
                    <div class="item">联系电话：{{info.mobile}}</div>
                    <!-- <div class="item">录入时间：{{info.create_timex}}</div> -->
                    <!-- <div class="item">工地/小区名称：{{info.quarters}}</div> -->
                    <!-- <div class="item">转化类型：{{info.source}}</div> -->
                    <!-- <div v-if="info.user" class="item">管理人：{{info.user.username}}</div> -->
                    <!-- <div v-if="info.remark" class="item">备注：{{info.remark}}</div> -->
                </div>
            </div>
            <div class="address-wrap">地址：{{info.quarters}}</div>
            <div class="tab-wrap">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="跟进记录" name="first">
                        <div v-if="follow.newest_month == ''">
                            <el-empty
                                description="暂无内容"
                                image="https://taocitong.oss-accelerate.aliyuncs.com/uploads/imgs/20220719/2d1c63f2080156299c18089b73718773.png">
                            </el-empty>
                        </div>
                        <div class="follow_box" v-else>
                            <div class="month">
                                {{follow.newest_month}}
                            </div>
                            <div class="follow_item" v-for="item in follow.list" :key="item.id">
                                <div class="left">
                                    <div class="date">
                                        {{item.create_timex_transform}}
                                    </div>
                                    <div class="line"></div>
                                </div>
                                <div class="right">
                                    <div class="content">
                                        {{item.contents}}
                                    </div>
                                    <div class="pic_box">
                                        <el-image fit="contain" class="pics" v-for="(img, index) in item.imgs" :key="index"
                                            :preview-src-list="item.imgs" :src="img"/>
                                    </div>
                                    <div class="others">
                                        <div class="sales">
                                            <div>
                                                跟进人：{{item.write_user_name}}
                                            </div>
                                            <div>
                                                {{item.create_timex.split(' ')[1]}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="成交记录" name="second">
                        <div v-if="dealList.newest_month == ''">
                            <el-empty
                                description="暂无内容"
                                image="https://taocitong.oss-accelerate.aliyuncs.com/uploads/imgs/20220719/2d1c63f2080156299c18089b73718773.png">
                            </el-empty>
                        </div>
                        <div class="follow_box" v-else>
                            <div class="month">
                                {{dealList.newest_month}}
                            </div>
                            <div class="follow_item" v-for="(item, index) in dealList.list" :key="index">
                                <div class="left">
                                    <div class="date">
                                        {{item.create_timex_transform}}
                                    </div>
                                    <div class="line"></div>
                                </div>
                                <div class="right">
                                    <div class="content">
                                        地址：{{item.business_address}}
                                    </div>
                                    <div class="content">
                                        <div>成交金额：{{item.business_gmv}}</div>
                                    </div>
                                    <div class="content">
                                        {{item.contents}}
                                    </div>
                                    <div class="pic_box">
                                        <el-image fit="contain" class="pics" v-for="(img, index) in item.imgs" :key="index"
                                            :preview-src-list="item.imgs" :src="img"/>
                                    </div>
                                    <div class="others">
                                        <div class="sales">
                                            <div>
                                                跟进人：{{item.write_user_name}}
                                            </div>
                                            <div>
                                                {{item.create_timex.split(' ')[1]}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="回访记录" name="third">
                        <div v-if="visitList.newest_month == ''">
                            <el-empty
                                description="暂无内容"
                                image="https://taocitong.oss-accelerate.aliyuncs.com/uploads/imgs/20220719/2d1c63f2080156299c18089b73718773.png">
                            </el-empty>
                        </div>
                        <div class="follow_box" v-else>
                            <div class="month">
                                {{visitList.newest_month}}
                            </div>
                            <div class="follow_item" v-for="(item, index) in visitList.list" :key="index">
                                <div class="left">
                                    <div class="date">
                                        {{item.create_timex_transform}}
                                    </div>
                                    <div class="line"></div>
                                </div>
                                <div class="right">
                                    <div class="content">
                                        {{item.contents}}
                                    </div>
                                    <div class="pic_box">
                                        <el-image fit="contain" class="pics" v-for="(img, index) in item.imgs" :key="index"
                                            :preview-src-list="item.imgs" :src="img"/>
                                    </div>
                                    <div class="fulfill_box" v-if="item.confirm">
                                        <div class="content">
                                            {{item.confirm && item.confirm.contents}}
                                        </div>
                                        <div class="pic_box">
                                            <el-image fit="contain" class="pics" v-for="(img, index) in item.confirm.imgs" :key="index"
                                            :preview-src-list="item.confirm.imgs" :src="img"/>
                                        </div>
                                    </div>
                                    <div class="others">
                                        <div class="sales">
                                            <div>
                                                跟进人：{{item.write_user_name}}
                                            </div>
                                            <div>
                                                {{item.create_timex.split(' ')[1]}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
export default {
    data () {
        return {
            info: {},
            follow: {},
            dealList: {},
            visitList: {},
            activeName: 'first',
            infoLoading: false,
        }
    },
    mounted () {
    },
    methods: {
        gotoClues(){
            this.$emit('showThread')
        },
        // 点击详情
        setValue(row){
            this.infoLoading = true;
            common.connect('/distributorpcv1/customer/show',{id: row.id},(res)=>{
                if(res.isSuccess == 1){
                    this.info = res.data.info;
                    res.data.follow.list.forEach(v=>{
                        v.imgs = JSON.parse(v.imgs)
                    })
                    res.data.deal.list.forEach(v=>{
                        v.imgs = JSON.parse(v.imgs)
                    })
                    res.data.return_visit.list.forEach(v=>{
                        v.imgs = JSON.parse(v.imgs);
                        if(v.confirm !=null){
                            v.confirm.imgs = JSON.parse(v.confirm.imgs)
                        }
                    })
                    this.follow = res.data.follow;
                    this.dealList = res.data.deal;
                    this.visitList = res.data.return_visit;
                }
                this.infoLoading = false;
            })
        },
    },
}
</script>

<style lang='scss'>
.customerDetails{
    .content-wrap{
        padding: 15px 0;
        .bar{
            height: 32px;
            background-color: #a8c2ee;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #09409f;
        }
        .info-wrap{
            padding: 20px;
            display: flex;
            flex-wrap: wrap;
            .head{
                width: 60px;
                height: 60px;
                .pic{
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                }
            }
            .info{
                width: calc(100% - 100px);
                margin-left: 15px;
                .tit-wrap{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    .tit{
                        font-size: 18px;
                        color: #000;
                        margin-bottom: 5px;
                    }
                    .clues{
                        color: #3272e1;
                        cursor: pointer;
                    }
                }
                .item{
                    font-size: 13px;
                    color: #666;
                    line-height: 20px;
                }
            }
        }
        .address-wrap{
            padding: 0px 20px 20px 20px;
            border-bottom: 1px solid #ddd;
            font-size: 14px;
            color: #666;
        }
        .tab-wrap{
            .el-tabs__nav-scroll{
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .el-tabs__item{
                padding: 0 45px;
            }
            .follow_box{
                padding: 0 20px;
                overflow-y: auto;
                height: 540px;
                .month {
                    font-size: 18px;
                    margin-bottom: 5px;
                    color: #000;
                }
                .follow_item {
                    display: flex;
                    margin-bottom: 5px;
                    .left {
                        margin-right: 20px;
                        overflow: hidden;
                        .date {
                            font-size: 12px;
                            font-style: italic;
                            margin-bottom: 4px;
                            padding: 0 3px;
                        }
                        .line {
                            width: 1px;
                            height: 100%;
                            background-color: #cccccc;
                            margin: 0 auto;
                        }
                    }
                    .right {
                        flex: 1;
                        padding-bottom: 10px;
                        .content {
                            font-size: 16px;
                            color: #000;
                        }
                        .pic_box {
                            display: flex;
                            flex-wrap: wrap;
                            padding-top: 10px;
                            .pics {
                                width: 60px;
                                margin-right: 10px;
                            }
                        }
                        .fulfill_box {
                            position: relative;
                            padding: 10px 15px;
                            background-color: #f2f2f2;
                            margin-top: 10px;
                            .content {
                                color: #999;
                            }
                            .renzhengshibai {
                                position: absolute;
                                top: 0;
                                right: 0;
                                font-size: 14px;
                                color: #999;
                                transform: translate(50%, -50%);
                            }
                        }
                        .others {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-top: 10px;
                            .sales {
                                line-height: 20px;
                                flex: 1;
                                font-size: 14px;
                                color: #999999;
                            }
                        }
                    }
                }
            }
            
        }
    }
}
</style>
